<template>
	<div class="zj-steper">
		<div class="step-minus" :title="`最小值为 ${min}`" @click="onMinus" ></div>
		<div class="step-input" :title="tip" ><input class="input" type="number" v-model="value" @keyup.enter="onSubmit" @blur="onSubmit" autocomplete="off"
			 onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" /></div>
		<div class="step-add" :title="`最大值为 ${max}`" @click="onAdd"></div>
	</div>
</template>

<script>
export default {
	name: 'ZjSteper',
	props: {
		default: {
			type: Number,
			default: 5
		},
		min: {
			type: Number,
			default: 1
		},
		max: {
			type: Number,
			default: 19
		},
		tip: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
			value: this.default,
		}
	},
	methods: {
		onSubmit(value) {
			console.log("onSubmit", this.value)
			if(this.value > this.max)
				this.value = this.max;
			else if(this.value < this.min)
				this.value = this.min;
			this.$emit('change', Number(this.value))
		},
		onAdd() {
			if(this.value < this.max){
				this.value++;
				this.$emit('change', this.value)
			}
		},
		onMinus() {
			if (this.value > this.min) {
				this.value--;
				this.$emit('change', this.value)
			}
		},
	},
	watch:{
		default(val, old){
			this.value = val;
		}
	},
}
</script>

<style scoped>	
input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {-webkit-appearance: none;}
input[type="number"] {-moz-appearance: textfield;}

/* 通用步进条 */
.zj-steper{ display: flex; padding: 5px 0 5px 0; align-items: center;}
.zj-steper .step-add{width: 20px; height: 20px; background: rgba(0, 0, 0, 0.5) url('./icon-add.svg'); background-size: 100% 100%; cursor: pointer; transition: all 0.5s 0s; border-radius: 2px; }
.zj-steper .step-minus{width: 20px; height: 20px; background: rgba(0, 0, 0, 0.5) url('./icon-minus.svg'); background-size: 100% 100%; cursor: pointer; transition: all 0.5s 0s; border-radius: 2px; }
.zj-steper .step-input{width: 40px; margin: 0 2px; }
.zj-steper .step-input input{display: flex; width: 40px; margin: 0; padding: 0; height: 20px; text-align: center; font-size: 16px; line-height: 20px; color: #FFF; background: rgba(0, 0, 0, 0.5); border-radius: 2px; border: none; outline: none; transition: all 0.5s 0s; }

.zj-steper .step-add:hover,.zj-steper .step-minus:hover{background-color: rgba(0, 0, 0, 0.8);}
.zj-steper .step-input:hover input{background-color: rgba(0, 0, 0, 0.8);}
</style>
