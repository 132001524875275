<template>
    <div class="zj-drop-menu">
        <div class="menu-box" v-for="(it,index) in list" :key=index @click="onMenu(it.key)" >
            <div class="icon"><img :src="it.icon" alt=""></div>
            <div class="menu">{{it.menu}}</div>
        </div>
    </div>
    
</template>

<script>

export default {
    name: 'ZjDropMenu',
    components: {
    },
    props: {
        list: {
            type: Array,
            default: () => [
                {
                    key: 'com1',
                    menu: '通讯录1',
                    icon: require("@/static/icons/icon-tel-note.svg"),
                },
                {
                    key: 'com2',
                    menu: '通讯录2',
                    icon: require("@/static/icons/icon-tel-note.svg"),
                },
            ],
        },
    },
    mounted() {
    },
    beforeDestroy() {
    },
    data(){
        return {
            isAllMic: false,
            isAllShut: false,
            layoutNumber: this.layout,
            
        }
    },
	methods: {
		onMenu(key){
			this.$emit('onMenu', key)
		},
	}
}
</script>

<style>
.zj-drop-menu{display: flex; top: 30px; right: 0; padding: 5px 0; border-radius: 10px; flex-direction: column; border: 2px solid #184084; background: rgba(14, 19, 52, 0.7); position: absolute; }
.zj-drop-menu .menu-box{display: flex; padding: 5px 15px; align-items: center; transition: all 0.5s 0s; }
.zj-drop-menu .menu-box .icon{ margin: 0 5px 0 0; width: 25px; height: 25px; overflow: hidden; }
.zj-drop-menu .menu-box .icon img{  width: auto; height: 100%; filter: drop-shadow(50px 0 0 #FFF); transform: translateX(-50px);  }
.zj-drop-menu .menu-box .menu{ color: #FFF; white-space: nowrap; font-size: 16px; }

.zj-drop-menu .menu-box:hover{background: rgba(0,64,128,1);}
.zj-drop-menu .menu-box:hover .icon img{filter: drop-shadow(50px 0 0 #40E090);}
.zj-drop-menu .menu-box:hover .menu{color: #40E090;}

</style>
