<template>
    <div class="com-top-bar" :class="isFrame ? 'no-bg': ''">
        <div class="title-bar" v-if="!isFrame" >
            <svg viewBoxs="0 0 700 72" class="svgBox">
                <defs>
                    <linearGradient id="SVGID_SOFTWARE_" gradientUnits="userSpaceOnUse" x1="0" y1="10" x2="0" y2="40">
                        <stop  offset="0" style="stop-color:#1e6dfa"/>
                        <stop  offset="0.5" style="stop-color:#bef5ff"/>
                        <stop  offset="1" style="stop-color:#1e6dfa"/>
                        <!-- <stop  offset="0" style="stop-color:#80C0FF"/> -->
                        <!-- <stop  offset="0.8" style="stop-color:#80E0FF"/> -->
                        <!-- <stop  offset="1" style="stop-color:#40C0FF"/> -->
                    </linearGradient>
                </defs>
                <text text-anchor="middle" class="title" x="350px" y="45px">{{title}}</text>
            </svg>
        </div>
        <!-- <div class="title-bar">{{title}}</div> -->
        <div class="control-area">
            <div class="button setting" title="视窗面板" >
                <img class="image" src="@/static/icons/icon-setting.svg" alt="" @click.stop="onSettings" >
                <!-- <zj-drop-menu :list="dropList" @onMenu="onMenu" /> -->
            </div>
            <div class="button exit" v-if="!isFrame" title="退出登录" @click="onQuit" >
                <img class="image" src="@/static/icons/icon-exit.svg" alt="">
            </div>
        </div>
        <div class="home-bar">
            <div class="home-icon" title="主页" v-if="!isFrame" ><a href="/"><img src="@/static/icons/icon-home.svg" alt=""></a></div>
            <div class="map-control-bar">
                <li class="type" :class="mapType=='normal' ? 'active' : ''" @click="onMapType('normal')"><img src="@/static/icons/icon-map-normal.png" alt="" title="道路地图" ></li>
                <li class="type" :class="mapType=='satellite' ? 'active' : ''" @click="onMapType('satellite')"><img src="@/static/icons/icon-map-satellite.png" title="卫星地图" alt=""></li>
                <li class="type" :class="mapType=='hybrid' ? 'active' : ''" @click="onMapType('hybrid')"><img src="@/static/icons/icon-map-hybrid.png" title="路网卫星地图" alt=""></li>
                <zj-steper class="zoom-box" :default="this.mapInfo.zoom" :min="0" :man="17" tip="地图缩放比例, 可输入范围为1~19之间" @change="onZoom"  />
            </div>
        </div>
    </div>
</template>

<script>
import ZjDropMenu from "@/components/zj-drop-menu/zj-drop-menu.vue"
import ZjSteper from "@/components/zj-steper/zj-steper.vue"
export default {
    name: 'ComTopBar',
    props: {
        isFrame: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ZjDropMenu,
        ZjSteper,
    },
    data(){
        return {
            title: "应急指挥调度数据可视化系统",
            mapType: 'hybrid',
            dropList: [
                {
                    key: 'comContact',
                    menu: '通讯录',
                    icon: require("@/static/icons/icon-person-hollow.svg"),
                },
                {
                    key: 'comDialUp',
                    menu: '拨号面板',
                    icon: require("@/static/icons/icon-call-hollow.svg"),
                },
                {
                    key: 'comVideoConference',
                    menu: '视频会议',
                    icon: require("@/static/icons/icon-camera-hollow.svg"),
                },
                {
                    key: 'comSentMessage',
                    menu: '发送消息',
                    icon: require("@/static/icons/icon-msg-hollow.svg"),
                },
                {
                    key: 'comSearchMap',
                    menu: '地图搜索',
                    icon: require("@/static/icons/icon-search.svg"),
                },
                {
                    key: 'comContact',
                    menu: '圈选',
                    icon: require("@/static/icons/icon-select-circle.svg"),
                },
                {
                    key: 'comContact',
                    menu: '测距',
                    icon: require("@/static/icons/icon-selete-ruler.svg"),
                },
                {
                    key: 'comContact',
                    menu: '测面',
                    icon: require("@/static/icons/icon-selete-face-hollow.svg"),
                },
                {
                    key: 'comContact',
                    menu: '区域切换',
                    icon: require("@/static/icons/icon-switch-hollow.svg"),
                },
                {
                    key: 'comContact',
                    menu: '清除',
                    icon: require("@/static/icons/icon-close-hollow.svg"),
                },
            ],
        }
    },
    computed:{
        mapInfo(){
            if(this.$store && this.$store.state.modMap)
                return this.$store.state.modMap;
            else
                return {zoom: 1}
        }
    },
    methods: {
        onSettings(){
            if(!this.$store)
                return;
            if(this.$store.state.modWindows['comViewsPanel'].is_show)
                this.$store.dispatch('closeWindow', 'comViewsPanel');
            else
                this.$store.dispatch('showWindow', 'comViewsPanel');
        },
        onMenu(key){
            // console.log("onMenu: " , key)
            this.$store.dispatch('showWindow', key);
        },
        onMapType(type){
            this.mapType = type;
            this.$store.dispatch('setMapType', type);
        },
        onZoom(value){
            console.log("onZoom: " , value)
            this.$store.dispatch('setMapZoom', value);
        },
        onQuit(value){
            this.$store.dispatch('setToken', '');
            this.$router.push({path: "/login"});
        },
    },
}
</script>

<style>
.com-top-bar{ height: 60px; background: url("../../static/images/top-bg.svg") top center no-repeat; background-size: auto 100% ; position: relative;  }
.com-top-bar.no-bg{background: none; }

.com-top-bar .title-bar{ color: #FFF; font-size: 32px; line-height: 60px; font-weight: bold; text-shadow: 0 0 10px rgba(0,0,0,1); position: relative;  /* -webkit-mask:linear-gradient(to bottom, rgba(255,0,0,0.8), rgba(255,255,255,0.0) ); */ }
.com-top-bar .title-bar .svgBox{ width: 700px; height: 60px; }
.com-top-bar .title-bar .title{ fill:url(#SVGID_SOFTWARE_); }

.com-top-bar .home-bar{ display: flex; top:50px; left: 20px; align-items: center; position: absolute;}
.com-top-bar .home-bar .home-icon{width: 30px; height: 30px; margin: 0 20px 0 0; cursor: pointer; }
.com-top-bar .home-bar .home-icon img{width: 100%; height: 100%;}
.com-top-bar .home-bar .map-control-bar{visibility: visible; display: flex; align-items: center; opacity: 1; transition: all 1s 0s;  }
.com-top-bar .home-bar .map-control-bar .type{width: 30px; height: 30px; margin: 0 5px 0 0; border: 1px solid rgba(255,255,255, 0.5); box-sizing: border-box; cursor: pointer; transition: all 0.5s 0s; }
.com-top-bar .home-bar .map-control-bar .type.active{border: 2px solid #40C0FF; box-shadow: 0 0 5px #000;}
.com-top-bar .home-bar .map-control-bar .type img{width: 100%;; height: 100%;}
.com-top-bar .home-bar .map-control-bar .zoom-box{margin: 0 0 0 10px; }
.com-top-bar .home-bar:hover .map-control-bar{visibility: visible; opacity: 1;}
.com-top-bar .home-bar .map-control-bar .type:hover{box-shadow: 0 0 2px #000;}


.com-top-bar .control-area {display: flex;  top: 50px; right: 20px; position: absolute; }
.com-top-bar .control-area .button{margin: 0 10px 0 0; width:30px; height:30px; cursor: pointer; position: relative; }
.com-top-bar .control-area .button .image{ width: auto; height: 100%; transition: transform 0.5s 0s; }
.com-top-bar .control-area .button:hover .image{ transform: rotate(-90deg); filter: drop-shadow(0 0 5px rgba(255,255,255,1)); }
.com-top-bar .control-area .button.setting .zj-drop-menu{ visibility: hidden;  height: 0; overflow: hidden; transition: all 0.5s 0s;}
.com-top-bar .control-area .button.setting:hover .zj-drop-menu{ visibility: visible; height: 400px;   }


</style>